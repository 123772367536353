import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  a {
    font-size: 2rem;
    color: white;
    text-decoration: none;
  }
`;

const Nav = () => {
  return (
    <NavBar>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <Link to="/">Groups</Link>
      <Link to="/rumble">Rumble</Link>
      <Link to="/results">Results</Link>
      <div />
    </NavBar>
  );
};

export default Nav;
