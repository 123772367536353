import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import "normalize.css";
import Nav from "./Nav";

const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
    background: #202020;
  }
  body {
    color: #fafafa;
    padding: 96;
    font-family: -apple-system, Roboto, sans-serif, serif
  }
  li {
    list-style: none;
    padding: 0;
  }
`;

const ContentStyles = styled.div`
  padding: 2rem;
`;

export default function Layout({ children }) {
  return (
    <>
      <Nav />
      <GlobalStyles />
      <ContentStyles>{children}</ContentStyles>
    </>
  );
}
